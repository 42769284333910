import { Button, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import BoxContent from '../../components/BoxContent';
import FormDatePicker from '../../components/form/FormDatePicker';
import FormGroup from '../../components/form/FormGroup';
import TituloPagina from '../../components/TituloPagina';
import api from '../../services/api';
import Row from '../../components/form/Row';

type IResponse = {
  pm_codigo: string;
  ciclo: string;
  ano: string;
  serial_number: string;
  valor_total_policial: string;
};

const RelatorioCGOIrsoCicloAno: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Date>();
  const toast = useToast();
  const ano = selectedDate?.getFullYear();
  const ciclo = (selectedDate?.getMonth() || 0) + 1;
  const [isLoading, setIsLoading] = useState(false);

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const fileName = `relatorio ${ciclo}-${ano}`;

  const exportToCSV = (dataCsv: any, filename: string): void => {
    const ws = XLSX.utils.json_to_sheet(dataCsv);
    // renameColumn(ws);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, filename + fileExtension);
  };

  function exportSerialNumbers(serialNumbers: string[]): void {
    const blob = new Blob([serialNumbers.join('\n')], { type: 'text/plain' });
    FileSaver.saveAs(blob, 'serial_pms.txt');
  }

  const handleRequestRelatorioCiclo = async (): Promise<void> => {
    if (!selectedDate)
      toast({
        title: 'Ocorreu um erro.',
        description: 'Data não selecionada',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    else {
      try {
        setIsLoading(true);
        const {
          data: { items },
        } = await api.get<{ items: IResponse[] }>(
          '/escalas/irsos/relatorios/pagamento_cgo',
          { params: { ano, ciclo } },
        );

        if (items.length < 1)
          toast({
            title: 'Aviso.',
            description: 'Não há polciais para esta data referida',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        else {
          const itemsCSV: Record<string, any>[] = [];
          const itemsTXT: string[] = [];

          items.forEach(({ valor_total_policial, serial_number, ...rest }) => {
            itemsTXT.push(serial_number);
            itemsCSV.push({
              ...rest,
              valor_total_policial: Number.parseFloat(valor_total_policial),
            });
          });

          exportToCSV(itemsCSV, fileName);
          exportSerialNumbers(itemsTXT);
        }
      } catch (error) {
        console.log(error);

        toast({
          title: 'Ocorreu um erro.',
          description:
            error.response?.data?.message ||
            'Relatório não pode ser gerado no momento',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <TituloPagina title="Relatório Finaneiro - CGO" />
      <BoxContent>
        <TituloPagina title="Selecione o ciclo e o ano para obter o relatório" />
        <Row>
          <FormGroup cols={[4, 6, 12]}>
            <FormDatePicker
              selected={selectedDate}
              onChange={(e) => setSelectedDate(e as Date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
            />
          </FormGroup>

          <FormGroup cols={[4, 6, 12]}>
            <Button
              leftIcon={<HiOutlineDocumentReport />}
              colorScheme="blue"
              onClick={handleRequestRelatorioCiclo}
              isLoading={isLoading}
              loadingText="Gerando Relatorio..."
            >
              Gerar Relatório
            </Button>
          </FormGroup>
        </Row>
      </BoxContent>
    </>
  );
};

export default RelatorioCGOIrsoCicloAno;
